<template>
  <div class="main">
    <el-form :label-position="'left'" label-width="80px" style="margin: 20px; width: 600px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="搜索">
            <el-input v-model="search" placeholder="title"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="onQuery">查询</el-button>
        </el-col>
      </el-row>
    </el-form>
    <div class="table">
      <el-table :data="tableData" highlight-current-row>
        <el-table-column label="id" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.homeworkId"></div>
          </template>
        </el-table-column>
        <el-table-column prop="courseId" label="courseId" width="100"></el-table-column>
        <el-table-column label="title" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.title"></div>
          </template>
        </el-table-column>
        <el-table-column label="key" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.key"></div>
          </template>
        </el-table-column>
        <el-table-column label="可抽的课文" width="350">
          <template slot-scope="scope">
            <div v-html="scope.row.config"></div>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" :formatter="formatTime" label="更新时间" width="200"></el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-button size="small" @click="onEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button type="danger" size="small" @click="onDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-col :span="24" class="toolbar">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="currentPage"
          :page-count="totalPage"
          @current-change="onChangePage"
        ></el-pagination>
      </el-col>
    </div>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";
import { userHomeWorkApi } from "@/api/userHomeWorkApi";
import { homeworkAssignConfigApi } from "@/api/homeworkAssignConfigApi";
import { alertMessage } from "@/util/alertMessageUtil.js";
import { strToEpoch } from "@/util/timeTool.js";
import { toNumber } from "@/util/numberUtil.js";
import { epochToDateString } from "@/util/TimeUtil";

const LEN = 20;

export default {
  components: {},
  data() {
    return {
      aidaUser: null,
      queryPhone: "",
      queryCourseId: "",
      hasReview: false,

      fromDay: "",
      fromTime: "",
      toDay: "",
      toTime: "",

      tableData: [],
      currentPage: 1,
      totalPage: 10,
      search: "",
    };
  },
  mounted() {
    this.onQuery();
  },
  methods: {
    onChangePage(val) {
      this.currentPage = val;
      let start = (this.currentPage - 1) * LEN;
      this.onQuery();
    },
    //获取列表
    onQuery() {
      let start = (this.currentPage - 1) * LEN;
      console.log("#1", this.search, start, LEN);
      homeworkAssignConfigApi
        .query(this.search, start, LEN)
        .then((ret) => {
          if (ret.code === 0) {
            console.log("--ret.data.data--", ret.data);
            this.tableData = ret.data;
            this.totalPage = ret.totalPage;
          } else {
            alertMessage(ret.msg, "warning");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onDelete(index, row) {
      let msg = "确定删除该项吗？";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          homeworkAssignConfigApi.deleteItem(row.homeworkId).then((res) => {
            if (res.code === 0) {
              alertMessage("删除成功", "success");
              this.tableData.splice(index, 1);
            } else {
              alertMessage(res.msg, "warning");
            }
          });
        })
        .catch(() => {});
    },
    onView(index, row) {
      let path = `/user-home-work/view?id=${row.id}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    onEdit(index, row) {
      let path = `/user-home-work/tis-add?id=${row.homeworkId}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    phonePrivacy(phone) {
      return phone.slice(0, 4) + "****" + phone.slice(7);
    },
    formatTime: function(row, column) {
      let num = Number(row[column.property]);
      return epochToDateString(num);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  .box-card {
    text-align: left;
    margin: 20px 0px;
  }
  .button-row {
    text-align: right;
    margin: 20px;
  }
  .table {
    width: 90%;
  }
}
</style>
